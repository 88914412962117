var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ManagementLayout',{directives:[{name:"intersect",rawName:"v-intersect",value:({ handler: _vm.onIntersect }),expression:"{ handler: onIntersect }"}],scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Your Teams")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v(" View and manage your team memberships ")]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-2 rounded-0 truncate-table",attrs:{"fixed-header":"","headers":_vm.headers,"header-props":{ 'sort-icon': 'arrow_drop_up' },"items":_vm.items,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'first_page',
      lastIcon: 'last_page',
      prevIcon: 'keyboard_arrow_left',
      nextIcon: 'keyboard_arrow_right'
    }},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
    var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text.toUpperCase()))])]}},{key:"header.slug",fn:function(ref){
    var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text.toUpperCase()))])]}},{key:"header.role",fn:function(ref){
    var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text.toUpperCase()))])]}},{key:"item.id",fn:function(ref){
    var item = ref.item;
return [(!item.member)?_c('AcceptConfirmInputRow',{attrs:{"loading":_vm.handlingInvitationLoad,"tooltips":true},on:{"accept":function($event){return _vm.handleAcceptPendingInvitation(
            item.id,
            item.tenant.name,
            item.tenant.slug
          )},"decline":function($event){return _vm.handleDeclinePendingInvitation(item.id, item.tenant.name)}}}):_vm._e(),_c('div',{staticStyle:{"padding-right":"6px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(item.tenant.id !== _vm.tenant.id && item.member)?_c('v-btn',_vm._g({staticStyle:{"margin-right":"14px"},attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.handleSwitchTenant(item.tenant)}}},on),[_c('v-icon',[_vm._v("swap_horiz")])],1):_vm._e()]}}],null,true)},[_vm._v("Switch to this team")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(item.member)?_c('v-btn',_vm._g({attrs:{"text":"","small":"","color":"error"},on:{"click":function($event){_vm.dialogRemoveUser = true
                _vm.removeTenant = item.tenant}}},on),[_c('v-icon',[_vm._v("close")])],1):_vm._e()]}}],null,true)},[_vm._v("Leave this team")])],1),(_vm.removeTenant)?_c('ConfirmDialog',{attrs:{"type":"error","title":("Are you sure you want to remove yourself from " + (_vm.removeTenant.name) + "?"),"dialog-props":{ 'max-width': '600' },"disabled":(_vm.isLastTenant && _vm.confirmInput !== _vm.tenant.slug) || _vm.isRemovingUser,"loading":_vm.isRemovingUser},on:{"confirm":function($event){return _vm.removeUser(_vm.removeTenant)},"cancel":_vm.resetRemoveUserDialog},model:{value:(_vm.dialogRemoveUser),callback:function ($$v) {_vm.dialogRemoveUser=$$v},expression:"dialogRemoveUser"}},[_c('div',[_c('div',{staticClass:"red--text mb-2"},[_vm._v("You'll no longer be able to access your run data associated with "+_vm._s(_vm.removeTenant.name)+".")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLastTenant),expression:"isLastTenant"}],staticClass:"mt-2"},[_c('div',{staticClass:"deepRed--text text-subtitle-1 font-weight-medium"},[_vm._v(" This is the last team you are part of. If you remove it you will not be able to log back in to Prefect Cloud. ")]),_c('div',{staticClass:"mt-4"},[_vm._v(" To confirm, type "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" your tenant URL slug ")]),_vm._v(" below: ")]),_c('v-text-field',{staticClass:"my-1",attrs:{"autocomplete":"off","placeholder":"Type your tenant URL slug","single-line":"","outlined":"","color":"primary","rules":[_vm.rules.required, _vm.rules.confirm],"loading":_vm.isRemovingUser},model:{value:(_vm.confirmInput),callback:function ($$v) {_vm.confirmInput=$$v},expression:"confirmInput"}})],1)])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }